import React, { useRef, Fragment, useState } from 'react';
import {
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Button,
  ClickAwayListener,
} from '@mui/material';
import { ChevronDown } from 'react-feather';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

const ButtonHistory = ({ disableAllExport = false, buttonSize = 'medium' }) => {
  const [open, setOpen] = useState(false);
  const warehouse = useSelector((state) => state.warehouse);

  console.log('Warehouse in History', warehouse);
  const anchorRef = useRef(null);
  const { id } = useParams();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        size={buttonSize}
      >
        <Button onClick={handleToggle}>ประวัติ</Button>
        {!disableAllExport && (
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ChevronDown />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {warehouse?.history_import && (
                    <Link to={`/wms/history-import/${id}`}>
                      <MenuItem key="1">ประวัติการรับเข้า</MenuItem>{' '}
                    </Link>
                  )}
                  {warehouse?.history_export && (
                    <Link to={`/wms/history-export/${id}`}>
                      <MenuItem
                        key="2"
                        onClick={(event) => handleToggle(event)}
                      >
                        ประวัติการส่งออก
                      </MenuItem>
                    </Link>
                  )}
                  {warehouse?.history_move && (
                    <Link to={`/wms/history-move/${id}`}>
                      <MenuItem
                        key="3"
                        onClick={(event) => handleToggle(event)}
                      >
                        ประวัติการย้ายคลังสินค้า
                      </MenuItem>
                    </Link>
                  )}
                  {warehouse?.history_stocklot && (
                    <Link to={`/wms/history-cut-stock/${id}`}>
                      <MenuItem
                        key="4"
                        onClick={(event) => handleToggle(event)}
                      >
                        ประวัติการตัดสต๊อกสินค้า
                      </MenuItem>
                    </Link>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

ButtonHistory.propTypes = {
  buttonSize: PropTypes.string,
};

export default ButtonHistory;
